import { useState, useMemo } from 'react';
import { Album } from '../../store/reducers/user';
import { LinkContainer, Link } from './AlbumPhotoListStyles';
import PhotoListItems from './PhotoListItems';
type Props = {
  album: Album;
};

const AlbumPhotoList = ({ album }: Props) => {
  const [isAllPhotos, setAllPhotos] = useState(false);
  const photos = useMemo(() => (isAllPhotos ? album.allPhotos : album.photos) ?? [], [isAllPhotos]);

  return (
    <>
      {album?.allow_all_photos && album.allPhotos?.length && (
        <LinkContainer>
          <Link active={!isAllPhotos} onClick={() => setAllPhotos(false)}>
            Photos of me
          </Link>
          <Link active={isAllPhotos} onClick={() => setAllPhotos(true)}>
            All Photos ({album.allPhotos?.length ?? 0})
          </Link>
        </LinkContainer>
      )}
      <PhotoListItems photos={photos} />
    </>
  );
};

export default AlbumPhotoList;
