import styled from "styled-components";

export const LinkContainer = styled.div`
  margin: 0;
  margin-left: 15px;
  gap: 12px;
  padding: 15px 0;
  display: flex;
  @media (min-width: 1440px) {
    max-width: 1200px;
    margin: 0 auto 0;
  }
`;

export const Link = styled.span<{ active: boolean}>`
  display: inline;
  color: #6D6D6D;
  text-decoration: none;
  text-underline: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 5px;

  ${(params) => params.active && `
    border-bottom: 2px solid #3300CC;
    color: #3300CC;
  `}
`;
