export interface CountryFromList {
    country: string;
    code: string;
    iso: string;
    src: string;
}

export const countryList = [
    {
        country: 'Afghanistan',
        code: '93',
        iso: 'AF',
        src: '/assets/flags/flag-for-afghanistan_1f1e6-1f1eb.png',
    },
    {
        country: 'Albania',
        code: '355',
        iso: 'AL',
        src: '/assets/flags/flag-for-albania_1f1e6-1f1f1.png',
    },
    {
        country: 'Algeria',
        code: '213',
        iso: 'DZ',
        src: '/assets/flags/flag-for-algeria_1f1e9-1f1ff.png',
    },
    {
        country: 'American Samoa',
        code: '1684',
        iso: 'AS',
        src: '/assets/flags/flag-for-american-samoa_1f1e6-1f1f8.png',
    },
    {
        country: 'Andorra',
        code: '376',
        iso: 'AD',
        src: '/assets/flags/flag-for-andorra_1f1e6-1f1e9.png',
    },
    {
        country: 'Angola',
        code: '244',
        iso: 'AO',
        src: '/assets/flags/flag-for-angola_1f1e6-1f1f4.png',
    },
    {
        country: 'Anguilla',
        code: '1264',
        iso: 'AI',
        src: '/assets/flags/flag-for-anguilla_1f1e6-1f1ee.png',
    },
    {
        country: 'Antarctica',
        code: '672',
        iso: 'AQ',
        src: '/assets/flags/flag-for-antarctica_1f1e6-1f1f6.png',
    },
    {
        country: 'Antigua and Barbuda',
        code: '1268',
        iso: 'AG',
        src: '/assets/flags/flag-for-antigua-barbuda_1f1e6-1f1ec.png',
    },
    {
        country: 'Argentina',
        code: '54',
        iso: 'AR',
        src: '/assets/flags/flag-for-argentina_1f1e6-1f1f7.png',
    },
    {
        country: 'Armenia',
        code: '374',
        iso: 'AM',
        src: '/assets/flags/flag-for-armenia_1f1e6-1f1f2.png',
    },
    {
        country: 'Aruba',
        code: '297',
        iso: 'AW',
        src: '/assets/flags/flag-for-aruba_1f1e6-1f1fc.png',
    },
    {
        country: 'Australia',
        code: '61',
        iso: 'AU',
        src: '/assets/flags/flag-for-australia_1f1e6-1f1fa.png',
    },
    {
        country: 'Austria',
        code: '43',
        iso: 'AT',
        src: '/assets/flags/flag-for-austria_1f1e6-1f1f9.png',
    },
    {
        country: 'Azerbaijan',
        code: '994',
        iso: 'AZ',
        src: '/assets/flags/flag-for-azerbaijan_1f1e6-1f1ff.png',
    },
    {
        country: 'Bahamas',
        code: '1242',
        iso: 'BS',
        src: '/assets/flags/flag-for-bahamas_1f1e7-1f1f8.png',
    },
    {
        country: 'Bahrain',
        code: '973',
        iso: 'BH',
        src: '/assets/flags/flag-for-bahrain_1f1e7-1f1ed.png',
    },
    {
        country: 'Bangladesh',
        code: '880',
        iso: 'BD',
        src: '/assets/flags/flag-for-bangladesh_1f1e7-1f1e9.png',
    },
    {
        country: 'Barbados',
        code: '1 246',
        iso: 'BB',
        src: '/assets/flags/flag-for-barbados_1f1e7-1f1e7.png',
    },
    {
        country: 'Belarus',
        code: '375',
        iso: 'BY',
        src: '/assets/flags/flag-for-belarus_1f1e7-1f1fe.png',
    },
    {
        country: 'Belgium',
        code: '32',
        iso: 'BE',
        src: '/assets/flags/flag-for-belgium_1f1e7-1f1ea.png',
    },
    {
        country: 'Belize',
        code: '501',
        iso: 'BZ',
        src: '/assets/flags/flag-for-belize_1f1e7-1f1ff.png',
    },
    {
        country: 'Benin',
        code: '229',
        iso: 'BJ',
        src: '/assets/flags/flag-for-benin_1f1e7-1f1ef.png',
    },
    {
        country: 'Bermuda',
        code: '1441',
        iso: 'BM',
        src: '/assets/flags/flag-for-bermuda_1f1e7-1f1f2.png',
    },
    {
        country: 'Bhutan',
        code: '975',
        iso: 'BT',
        src: '/assets/flags/flag-for-bhutan_1f1e7-1f1f9.png',
    },
    {
        country: 'Bolivia',
        code: '591',
        iso: 'BO',
        src: '/assets/flags/flag-for-bolivia_1f1e7-1f1f4.png',
    },
    {
        country: 'Bosnia and Herzegovina',
        code: '387',
        iso: 'BA',
        src: '/assets/flags/flag-for-bosnia-herzegovina_1f1e7-1f1e6.png',
    },
    {
        country: 'Botswana',
        code: '267',
        iso: 'BW',
        src: '/assets/flags/flag-for-botswana_1f1e7-1f1fc.png',
    },
    {
        country: 'Brazil',
        code: '55',
        iso: 'BR',
        src: '/assets/flags/flag-for-brazil_1f1e7-1f1f7.png',
    },
    {
        country: 'British Indian Ocean Territory',
        code: '246',
        iso: 'IO',
        src: '/assets/flags/flag-for-british-indian-ocean-territory_1f1ee-1f1f4.png',
    },
    {
        country: 'British Virgin Islands',
        code: '1284',
        iso: 'VG',
        src: '/assets/flags/flag-for-british-virgin-islands_1f1fb-1f1ec.png',
    },
    {
        country: 'Brunei',
        code: '673',
        iso: 'BN',
        src: '/assets/flags/flag-for-brunei_1f1e7-1f1f3.png',
    },
    {
        country: 'Bulgaria',
        code: '359',
        iso: 'BG',
        src: '/assets/flags/flag-for-bulgaria_1f1e7-1f1ec.png',
    },
    {
        country: 'Burkina Faso',
        code: '226',
        iso: 'BF',
        src: '/assets/flags/flag-for-burkina-faso_1f1e7-1f1eb.png',
    },
    {
        country: 'Burundi',
        code: '257',
        iso: 'BI',
        src: '/assets/flags/flag-for-burundi_1f1e7-1f1ee.png',
    },
    {
        country: 'Cambodia',
        code: '855',
        iso: 'KH',
        src: '/assets/flags/flag-for-cambodia_1f1f0-1f1ed.png',
    },
    {
        country: 'Cameroon',
        code: '237',
        iso: 'CM',
        src: '/assets/flags/flag-for-cameroon_1f1e8-1f1f2.png',
    },
    {
        country: 'Canada',
        code: '1',
        iso: 'CA',
        src: '/assets/flags/flag-for-canada_1f1e8-1f1e6.png',
    },
    {
        country: 'Cape Verde',
        code: '238',
        iso: 'CV',
        src: '/assets/flags/flag-for-cape-verde_1f1e8-1f1fb.png',
    },
    {
        country: 'Cayman Islands',
        code: '1345',
        iso: 'KY',
        src: '/assets/flags/flag-for-cayman-islands_1f1f0-1f1fe.png',
    },
    {
        country: 'Central African Republic',
        code: '236',
        iso: 'CF',
        src: '/assets/flags/flag-for-central-african-republic_1f1e8-1f1eb.png',
    },
    {
        country: 'Chad',
        code: '235',
        iso: 'TD',
        src: '/assets/flags/flag-for-chad_1f1f9-1f1e9.png',
    },
    {
        country: 'Chile',
        code: '56',
        iso: 'CL',
        src: '/assets/flags/flag-for-chile_1f1e8-1f1f1.png',
    },
    {
        country: 'China',
        code: '86',
        iso: 'CN',
        src: '/assets/flags/flag-for-china_1f1e8-1f1f3.png',
    },
    {
        country: 'Christmas Island',
        code: '61',
        iso: 'CX',
        src: '/assets/flags/flag-for-christmas-island_1f1e8-1f1fd.png',
    },
    {
        country: 'Cocos Islands',
        code: '61',
        iso: 'CC',
        src: '/assets/flags/flag-for-cocos-islands_1f1e8-1f1e8.png',
    },
    {
        country: 'Colombia',
        code: '57',
        iso: 'CO',
        src: '/assets/flags/flag-for-colombia_1f1e8-1f1f4.png',
    },
    {
        country: 'Comoros',
        code: '269',
        iso: 'KM',
        src: '/assets/flags/flag-for-comoros_1f1f0-1f1f2.png',
    },
    {
        country: 'Cook Islands',
        code: '682',
        iso: 'CK',
        src: '/assets/flags/flag-for-cook-islands_1f1e8-1f1f0.png',
    },
    {
        country: 'Costa Rica',
        code: '506',
        iso: 'CR',
        src: '/assets/flags/flag-for-costa-rica_1f1e8-1f1f7.png',
    },
    {
        country: 'Croatia',
        code: '385',
        iso: 'HR',
        src: '/assets/flags/flag-for-croatia_1f1ed-1f1f7.png',
    },
    {
        country: 'Cuba',
        code: '53',
        iso: 'CU',
        src: '/assets/flags/flag-for-cuba_1f1e8-1f1fa.png',
    },
    {
        country: 'Curacao',
        code: '599',
        iso: 'CW',
        src: '/assets/flags/flag-for-curacao_1f1e8-1f1fc.png',
    },
    {
        country: 'Cyprus',
        code: '357',
        iso: 'CY',
        src: '/assets/flags/flag-for-cyprus_1f1e8-1f1fe.png',
    },
    {
        country: 'Czech Republic',
        code: '420',
        iso: 'CZ',
        src: '/assets/flags/flag-for-czech-republic_1f1e8-1f1ff.png',
    },
    {
        country: 'Democratic Republic of the Congo',
        code: '243',
        iso: 'CD',
        src: '/assets/flags/flag-for-congo-kinshasa_1f1e8-1f1e9.png',
    },
    {
        country: 'Denmark',
        code: '45',
        iso: 'DK',
        src: '/assets/flags/flag-for-denmark_1f1e9-1f1f0.png',
    },
    {
        country: 'Djibouti',
        code: '253',
        iso: 'DJ',
        src: '/assets/flags/flag-for-djibouti_1f1e9-1f1ef.png',
    },
    {
        country: 'Dominica',
        code: '1767',
        iso: 'DM',
        src: '/assets/flags/flag-for-dominica_1f1e9-1f1f2.png',
    },
    {
        country: 'Dominican Republic',
        code: '1809',
        iso: 'DO',
        src: '/assets/flags/flag-for-dominican-republic_1f1e9-1f1f4.png',
    },
    {
        country: 'East Timor',
        code: '670',
        iso: 'TL',
        src: '/assets/flags/flag-for-timor-leste_1f1f9-1f1f1.png',
    },
    {
        country: 'Ecuador',
        code: '593',
        iso: 'EC',
        src: '/assets/flags/flag-for-ecuador_1f1ea-1f1e8.png',
    },
    {
        country: 'Egypt',
        code: '20',
        iso: 'EG',
        src: '/assets/flags/flag-for-egypt_1f1ea-1f1ec.png',
    },
    {
        country: 'El Salvador',
        code: '503',
        iso: 'SV',
        src: '/assets/flags/flag-for-el-salvador_1f1f8-1f1fb.png',
    },
    {
        country: 'Equatorial Guinea',
        code: '240',
        iso: 'GQ',
        src: '/assets/flags/flag-for-equatorial-guinea_1f1ec-1f1f6.png',
    },
    {
        country: 'Eritrea',
        code: '291',
        iso: 'ER',
        src: '/assets/flags/flag-for-eritrea_1f1ea-1f1f7.png',
    },
    {
        country: 'Estonia',
        code: '372',
        iso: 'EE',
        src: '/assets/flags/flag-for-estonia_1f1ea-1f1ea.png',
    },
    {
        country: 'Ethiopia',
        code: '251',
        iso: 'ET',
        src: '/assets/flags/flag-for-ethiopia_1f1ea-1f1f9.png',
    },
    {
        country: 'Falkland Islands',
        code: '500',
        iso: 'FK',
        src: '/assets/flags/flag-for-falkland-islands_1f1eb-1f1f0.png',
    },
    {
        country: 'Faroe Islands',
        code: '298',
        iso: 'FO',
        src: '/assets/flags/flag-for-faroe-islands_1f1eb-1f1f4.png',
    },
    {
        country: 'Fiji',
        code: '679',
        iso: 'FJ',
        src: '/assets/flags/flag-for-fiji_1f1eb-1f1ef.png',
    },
    {
        country: 'Finland',
        code: '358',
        iso: 'FI',
        src: '/assets/flags/flag-for-finland_1f1eb-1f1ee.png',
    },
    {
        country: 'France',
        code: '33',
        iso: 'FR',
        src: '/assets/flags/flag-for-france_1f1eb-1f1f7.png',
    },
    {
        country: 'French Polynesia',
        code: '689',
        iso: 'PF',
        src: '/assets/flags/flag-for-french-polynesia_1f1f5-1f1eb.png',
    },
    {
        country: 'french Guiana',
        code: '594',
        iso: 'GF',
        src: '/assets/flags/flag-for-french-guiana_1f1ec-1f1eb.png',
    },
    {
        country: 'Gabon',
        code: '241',
        iso: 'GA',
        src: '/assets/flags/flag-for-gabon_1f1ec-1f1e6.png',
    },
    {
        country: 'Gambia',
        code: '220',
        iso: 'GM',
        src: '/assets/flags/flag-for-gambia_1f1ec-1f1f2.png',
    },
    {
        country: 'Georgia',
        code: '995',
        iso: 'GE',
        src: '/assets/flags/flag-for-georgia_1f1ec-1f1ea.png',
    },
    {
        country: 'Germany',
        code: '49',
        iso: 'DE',
        src: '/assets/flags/flag-for-germany_1f1e9-1f1ea.png',
    },
    {
        country: 'Ghana',
        code: '233',
        iso: 'GH',
        src: '/assets/flags/flag-for-ghana_1f1ec-1f1ed.png',
    },
    {
        country: 'Gibraltar',
        code: '350',
        iso: 'GI',
        src: '/assets/flags/flag-for-gibraltar_1f1ec-1f1ee.png',
    },
    {
        country: 'Greece',
        code: '30',
        iso: 'GR',
        src: '/assets/flags/flag-for-greece_1f1ec-1f1f7.png',
    },
    {
        country: 'Greenland',
        code: '299',
        iso: 'GL',
        src: '/assets/flags/flag-for-greenland_1f1ec-1f1f1.png',
    },
    {
        country: 'Grenada',
        code: '1473',
        iso: 'GD',
        src: '/assets/flags/flag-for-grenada_1f1ec-1f1e9.png',
    },
    {
        country: 'Guam',
        code: '1671',
        iso: 'GU',
        src: '/assets/flags/flag-for-guam_1f1ec-1f1fa.png',
    },
    {
        country: 'Guadeloupe',
        code: '590',
        iso: 'GP',
        src: '/assets/flags/flag-for-guadeloupe_1f1ec-1f1f5.png',
    },
    {
        country: 'Guatemala',
        code: '502',
        iso: 'GT',
        src: '/assets/flags/flag-for-guatemala_1f1ec-1f1f9.png',
    },
    {
        country: 'Guernsey',
        code: '441481',
        iso: 'GG',
        src: '/assets/flags/flag-for-guernsey_1f1ec-1f1ec.png',
    },
    {
        country: 'Guinea',
        code: '224',
        iso: 'GN',
        src: '/assets/flags/flag-for-guinea_1f1ec-1f1f3.png',
    },
    {
        country: 'Guinea-Bissau',
        code: '245',
        iso: 'GW',
        src: '/assets/flags/flag-for-guinea-bissau_1f1ec-1f1fc.png',
    },
    {
        country: 'Guyana',
        code: '592',
        iso: 'GY',
        src: '/assets/flags/flag-for-guyana_1f1ec-1f1fe.png',
    },
    {
        country: 'Haiti',
        code: '509',
        iso: 'HT',
        src: '/assets/flags/flag-for-haiti_1f1ed-1f1f9.png',
    },
    {
        country: 'Honduras',
        code: '504',
        iso: 'HN',
        src: '/assets/flags/flag-for-honduras_1f1ed-1f1f3.png',
    },
    {
        country: 'Hong Kong',
        code: '852',
        iso: 'HK',
        src: '/assets/flags/flag-for-hong-kong_1f1ed-1f1f0.png',
    },
    {
        country: 'Hungary',
        code: '36',
        iso: 'HU',
        src: '/assets/flags/flag-for-hungary_1f1ed-1f1fa.png',
    },
    {
        country: 'Iceland',
        code: '354',
        iso: 'IS',
        src: '/assets/flags/flag-for-iceland_1f1ee-1f1f8.png',
    },
    {
        country: 'India',
        code: '91',
        iso: 'IN',
        src: '/assets/flags/flag-for-india_1f1ee-1f1f3.png',
    },
    {
        country: 'Indonesia',
        code: '62',
        iso: 'ID',
        src: '/assets/flags/flag-for-indonesia_1f1ee-1f1e9.png',
    },
    {
        country: 'Iran',
        code: '98',
        iso: 'IR',
        src: '/assets/flags/flag-for-iran_1f1ee-1f1f7.png',
    },
    {
        country: 'Iraq',
        code: '964',
        iso: 'IQ',
        src: '/assets/flags/flag-for-iraq_1f1ee-1f1f6.png',
    },
    {
        country: 'Ireland',
        code: '353',
        iso: 'IE',
        src: '/assets/flags/flag-for-ireland_1f1ee-1f1ea.png',
    },
    {
        country: 'Isle of Man',
        code: '441624',
        iso: 'IM',
        src: '/assets/flags/flag-for-isle-of-man_1f1ee-1f1f2.png',
    },
    {
        country: 'Israel',
        code: '972',
        iso: 'IL',
        src: '/assets/flags/flag-for-israel_1f1ee-1f1f1.png',
    },
    {
        country: 'Italy',
        code: '39',
        iso: 'IT',
        src: '/assets/flags/flag-for-italy_1f1ee-1f1f9.png',
    },
    {
        country: 'Ivory Coast',
        code: '225',
        iso: 'CI',
        src: '/assets/flags/flag-for-cote-divoire_1f1e8-1f1ee.png',
    },
    {
        country: 'Jamaica',
        code: '1876',
        iso: 'JM',
        src: '/assets/flags/flag-for-jamaica_1f1ef-1f1f2.png',
    },
    {
        country: 'Japan',
        code: '81',
        iso: 'JP',
        src: '/assets/flags/flag-for-japan_1f1ef-1f1f5.png',
    },
    {
        country: 'Jersey',
        code: '441534',
        iso: 'JE',
        src: '/assets/flags/flag-for-jersey_1f1ef-1f1ea.png',
    },
    {
        country: 'Jordan',
        code: '962',
        iso: 'JO',
        src: '/assets/flags/flag-for-jordan_1f1ef-1f1f4.png',
    },
    {
        country: 'Kazakhstan',
        code: '7',
        iso: 'KZ',
        src: '/assets/flags/flag-for-kazakhstan_1f1f0-1f1ff.png',
    },
    {
        country: 'Kenya',
        code: '254',
        iso: 'KE',
        src: '/assets/flags/flag-for-kenya_1f1f0-1f1ea.png',
    },
    {
        country: 'Kiribati',
        code: '686',
        iso: 'KI',
        src: '/assets/flags/flag-for-kiribati_1f1f0-1f1ee.png',
    },
    {
        country: 'Kosovo',
        code: '383',
        iso: 'XK',
        src: '/assets/flags/flag-for-kosovo_1f1fd-1f1f0.png',
    },
    {
        country: 'Kuwait',
        code: '965',
        iso: 'KW',
        src: '/assets/flags/flag-for-kuwait_1f1f0-1f1fc.png',
    },
    {
        country: 'Kyrgyzstan',
        code: '996',
        iso: 'KG',
        src: '/assets/flags/flag-for-kyrgyzstan_1f1f0-1f1ec.png',
    },
    {
        country: 'Laos',
        code: '856',
        iso: 'LA',
        src: '/assets/flags/flag-for-laos_1f1f1-1f1e6.png',
    },
    {
        country: 'Latvia',
        code: '371',
        iso: 'LV',
        src: '/assets/flags/flag-for-latvia_1f1f1-1f1fb.png',
    },
    {
        country: 'Lebanon',
        code: '961',
        iso: 'LB',
        src: '/assets/flags/flag-for-lebanon_1f1f1-1f1e7.png',
    },
    {
        country: 'Lesotho',
        code: '266',
        iso: 'LS',
        src: '/assets/flags/flag-for-lesotho_1f1f1-1f1f8.png',
    },
    {
        country: 'Liberia',
        code: '231',
        iso: 'LR',
        src: '/assets/flags/flag-for-liberia_1f1f1-1f1f7.png',
    },
    {
        country: 'Libya',
        code: '218',
        iso: 'LY',
        src: '/assets/flags/flag-for-libya_1f1f1-1f1fe.png',
    },
    {
        country: 'Liechtenstein',
        code: '423',
        iso: 'LI',
        src: '/assets/flags/flag-for-liechtenstein_1f1f1-1f1ee.png',
    },
    {
        country: 'Lithuania',
        code: '370',
        iso: 'LT',
        src: '/assets/flags/flag-for-lithuania_1f1f1-1f1f9.png',
    },
    {
        country: 'Luxembourg',
        code: '352',
        iso: 'LU',
        src: '/assets/flags/flag-for-luxembourg_1f1f1-1f1fa.png',
    },
    {
        country: 'Macau',
        code: '853',
        iso: 'MO',
        src: '/assets/flags/flag-for-macau_1f1f2-1f1f4.png',
    },
    {
        country: 'Macedonia',
        code: '389',
        iso: 'MK',
        src: '/assets/flags/flag-for-macedonia_1f1f2-1f1f0.png',
    },
    {
        country: 'Madagascar',
        code: '261',
        iso: 'MG',
        src: '/assets/flags/flag-for-madagascar_1f1f2-1f1ec.png',
    },
    {
        country: 'Malawi',
        code: '265',
        iso: 'MW',
        src: '/assets/flags/flag-for-malawi_1f1f2-1f1fc.png',
    },
    {
        country: 'Malaysia',
        code: '60',
        iso: 'MY',
        src: '/assets/flags/flag-for-malaysia_1f1f2-1f1fe.png',
    },
    {
        country: 'Maldives',
        code: '960',
        iso: 'MV',
        src: '/assets/flags/flag-for-maldives_1f1f2-1f1fb.png',
    },
    {
        country: 'Mali',
        code: '223',
        iso: 'ML',
        src: '/assets/flags/flag-for-mali_1f1f2-1f1f1.png',
    },
    {
        country: 'Malta',
        code: '356',
        iso: 'MT',
        src: '/assets/flags/flag-for-malta_1f1f2-1f1f9.png',
    },
    {
        country: 'Marshall Islands',
        code: '692',
        iso: 'MH',
        src: '/assets/flags/flag-for-marshall-islands_1f1f2-1f1ed.png',
    },
    {
        country: 'Martinique',
        code: '596',
        iso: 'MQ',
        src: '/assets/flags/flag-for-martinique_1f1f2-1f1f6.png',
    },
    {
        country: 'Mauritania',
        code: '222',
        iso: 'MR',
        src: '/assets/flags/flag-for-mauritania_1f1f2-1f1f7.png',
    },
    {
        country: 'Mauritius',
        code: '230',
        iso: 'MU',
        src: '/assets/flags/flag-for-mauritius_1f1f2-1f1fa.png',
    },
    {
        country: 'Mayotte',
        code: '262',
        iso: 'YT',
        src: '/assets/flags/flag-for-mayotte_1f1fe-1f1f9.png',
    },
    {
        country: 'Mexico',
        code: '52',
        iso: 'MX',
        src: '/assets/flags/flag-for-mexico_1f1f2-1f1fd.png',
    },
    {
        country: 'Micronesia',
        code: '691',
        iso: 'FM',
        src: '/assets/flags/flag-for-micronesia_1f1eb-1f1f2.png',
    },
    {
        country: 'Moldova',
        code: '373',
        iso: 'MD',
        src: '/assets/flags/flag-for-moldova_1f1f2-1f1e9.png',
    },
    {
        country: 'Monaco',
        code: '377',
        iso: 'MC',
        src: '/assets/flags/flag-for-monaco_1f1f2-1f1e8.png',
    },
    {
        country: 'Mongolia',
        code: '976',
        iso: 'MN',
        src: '/assets/flags/flag-for-mongolia_1f1f2-1f1f3.png',
    },
    {
        country: 'Montenegro',
        code: '382',
        iso: 'ME',
        src: '/assets/flags/flag-for-montenegro_1f1f2-1f1ea.png',
    },
    {
        country: 'Montserrat',
        code: '1664',
        iso: 'MS',
        src: '/assets/flags/flag-for-montserrat_1f1f2-1f1f8.png',
    },
    {
        country: 'Morocco',
        code: '212',
        iso: 'MA',
        src: '/assets/flags/flag-for-morocco_1f1f2-1f1e6.png',
    },
    {
        country: 'Mozambique',
        code: '258',
        iso: 'MZ',
        src: '/assets/flags/flag-for-mozambique_1f1f2-1f1ff.png',
    },
    {
        country: 'Myanmar',
        code: '95',
        iso: 'MM',
        src: '/assets/flags/flag-for-myanmar_1f1f2-1f1f2.png',
    },
    {
        country: 'Namibia',
        code: '264',
        iso: 'NA',
        src: '/assets/flags/flag-for-namibia_1f1f3-1f1e6.png',
    },
    {
        country: 'Nauru',
        code: '674',
        iso: 'NR',
        src: '/assets/flags/flag-for-nauru_1f1f3-1f1f7.png',
    },
    {
        country: 'Nepal',
        code: '977',
        iso: 'NP',
        src: '/assets/flags/flag-for-nepal_1f1f3-1f1f5.png',
    },
    {
        country: 'Netherlands',
        code: '31',
        iso: 'NL',
        src: '/assets/flags/flag-for-netherlands_1f1f3-1f1f1.png',
    },
    {
        country: 'Netherlands Antilles',
        code: '599',
        iso: 'AN',
        src: '/assets/flags/flag-for-netherlands_1f1f3-1f1f1.png',
    },
    {
        country: 'New Caledonia',
        code: '687',
        iso: 'NC',
        src: '/assets/flags/flag-for-new-caledonia_1f1f3-1f1e8.png',
    },
    {
        country: 'New Zealand',
        code: '64',
        iso: 'NZ',
        src: '/assets/flags/flag-for-new-zealand_1f1f3-1f1ff.png',
    },
    {
        country: 'Nicaragua',
        code: '505',
        iso: 'NI',
        src: '/assets/flags/flag-for-nicaragua_1f1f3-1f1ee.png',
    },
    {
        country: 'Niger',
        code: '227',
        iso: 'NE',
        src: '/assets/flags/flag-for-niger_1f1f3-1f1ea.png',
    },
    {
        country: 'Nigeria',
        code: '234',
        iso: 'NG',
        src: '/assets/flags/flag-for-nigeria_1f1f3-1f1ec.png',
    },
    {
        country: 'Niue',
        code: '683',
        iso: 'NU',
        src: '/assets/flags/flag-for-niue_1f1f3-1f1fa.png',
    },
    {
        country: 'North Korea',
        code: '850',
        iso: 'KP',
        src: '/assets/flags/flag-for-north-korea_1f1f0-1f1f5.png',
    },
    {
        country: 'Northern Mariana Islands',
        code: '1670',
        iso: 'MP',
        src: '/assets/flags/flag-for-northern-mariana-islands_1f1f2-1f1f5.png',
    },
    {
        country: 'Norway',
        code: '47',
        iso: 'NO',
        src: '/assets/flags/flag-for-norway_1f1f3-1f1f4.png',
    },
    {
        country: 'Oman',
        code: '968',
        iso: 'OM',
        src: '/assets/flags/flag-for-oman_1f1f4-1f1f2.png',
    },
    {
        country: 'Pakistan',
        code: '92',
        iso: 'PK',
        src: '/assets/flags/flag-for-pakistan_1f1f5-1f1f0.png',
    },
    {
        country: 'Palau',
        code: '680',
        iso: 'PW',
        src: '/assets/flags/flag-for-palau_1f1f5-1f1fc.png',
    },
    {
        country: 'Palestine',
        code: '970',
        iso: 'PS',
        src: '/assets/flags/flag-for-palestinian-territories_1f1f5-1f1f8.png',
    },
    {
        country: 'Panama',
        code: '507',
        iso: 'PA',
        src: '/assets/flags/flag-for-panama_1f1f5-1f1e6.png',
    },
    {
        country: 'Papua New Guinea',
        code: '675',
        iso: 'PG',
        src: '/assets/flags/flag-for-papua-new-guinea_1f1f5-1f1ec.png',
    },
    {
        country: 'Paraguay',
        code: '595',
        iso: 'PY',
        src: '/assets/flags/flag-for-paraguay_1f1f5-1f1fe.png',
    },
    {
        country: 'Peru',
        code: '51',
        iso: 'PE',
        src: '/assets/flags/flag-for-peru_1f1f5-1f1ea.png',
    },
    {
        country: 'Philippines',
        code: '63',
        iso: 'PH',
        src: '/assets/flags/flag-for-philippines_1f1f5-1f1ed.png',
    },
    {
        country: 'Pitcairn',
        code: '64',
        iso: 'PN',
        src: '/assets/flags/flag-for-pitcairn-islands_1f1f5-1f1f3.png',
    },
    {
        country: 'Poland',
        code: '48',
        iso: 'PL',
        src: '/assets/flags/flag-for-poland_1f1f5-1f1f1.png',
    },
    {
        country: 'Portugal',
        code: '351',
        iso: 'PT',
        src: '/assets/flags/flag-for-portugal_1f1f5-1f1f9.png',
    },
    {
        country: 'Puerto Rico',
        code: '1 787',
        iso: 'PR',
        src: '/assets/flags/flag-for-puerto-rico_1f1f5-1f1f7.png',
    },
    {
        country: 'Qatar',
        code: '974',
        iso: 'QA',
        src: '/assets/flags/flag-for-qatar_1f1f6-1f1e6.png',
    },
    {
        country: 'Republic of the Congo',
        code: '242',
        iso: 'CG',
        src: '/assets/flags/flag-for-congo-brazzaville_1f1e8-1f1ec.png',
    },
    {
        country: 'Reunion',
        code: '262',
        iso: 'RE',
        src: '/assets/flags/flag-for-reunion_1f1f7-1f1ea.png',
    },
    {
        country: 'Romania',
        code: '40',
        iso: 'RO',
        src: '/assets/flags/flag-for-romania_1f1f7-1f1f4.png',
    },
    {
        country: 'Russia',
        code: '7',
        iso: 'RU',
        src: '/assets/flags/flag-for-russia_1f1f7-1f1fa.png',
    },
    {
        country: 'Rwanda',
        code: '250',
        iso: 'RW',
        src: '/assets/flags/flag-for-rwanda_1f1f7-1f1fc.png',
    },
    {
        country: 'Saint Barthelemy',
        code: '590',
        iso: 'BL',
        src: '/assets/flags/flag-for-st-barthelemy_1f1e7-1f1f1.png',
    },
    {
        country: 'Saint Helena',
        code: '290',
        iso: 'SH',
        src: '/assets/flags/flag-for-st-helena_1f1f8-1f1ed.png',
    },
    {
        country: 'Saint Kitts and Nevis',
        code: '1869',
        iso: 'KN',
        src: '/assets/flags/flag-for-st-kitts-nevis_1f1f0-1f1f3.png',
    },
    {
        country: 'Saint Lucia',
        code: '1758',
        iso: 'LC',
        src: '/assets/flags/flag-for-st-lucia_1f1f1-1f1e8.png',
    },
    {
        country: 'Saint Martin',
        code: '590',
        iso: 'MF',
        src: '/assets/flags/flag-for-st-martin_1f1f2-1f1eb.png',
    },
    {
        country: 'Saint Pierre and Miquelon',
        code: '508',
        iso: 'PM',
        src: '/assets/flags/flag-for-st-pierre-miquelon_1f1f5-1f1f2.png',
    },
    {
        country: 'Saint Vincent and the Grenadines',
        code: '1784',
        iso: 'VC',
        src: '/assets/flags/flag-for-st-vincent-grenadines_1f1fb-1f1e8.png',
    },
    {
        country: 'Samoa',
        code: '685',
        iso: 'WS',
        src: '/assets/flags/flag-for-samoa_1f1fc-1f1f8.png',
    },
    {
        country: 'San Marino',
        code: '378',
        iso: 'SM',
        src: '/assets/flags/flag-for-san-marino_1f1f8-1f1f2.png',
    },
    {
        country: 'Sao Tome and Principe',
        code: '239',
        iso: 'ST',
        src: '/assets/flags/flag-for-sao-tome-principe_1f1f8-1f1f9.png',
    },
    {
        country: 'Saudi Arabia',
        code: '966',
        iso: 'SA',
        src: '/assets/flags/flag-for-saudi-arabia_1f1f8-1f1e6.png',
    },
    {
        country: 'Senegal',
        code: '221',
        iso: 'SN',
        src: '/assets/flags/flag-for-senegal_1f1f8-1f1f3.png',
    },
    {
        country: 'Serbia',
        code: '381',
        iso: 'RS',
        src: '/assets/flags/flag-for-serbia_1f1f7-1f1f8.png',
    },
    {
        country: 'Seychelles',
        code: '248',
        iso: 'SC',
        src: '/assets/flags/flag-for-seychelles_1f1f8-1f1e8.png',
    },
    {
        country: 'Sierra Leone',
        code: '232',
        iso: 'SL',
        src: '/assets/flags/flag-for-sierra-leone_1f1f8-1f1f1.png',
    },
    {
        country: 'Singapore',
        code: '65',
        iso: 'SG',
        src: '/assets/flags/flag-for-singapore_1f1f8-1f1ec.png',
    },
    {
        country: 'Sint Maarten',
        code: '1721',
        iso: 'SX',
        src: '/assets/flags/flag-for-sint-maarten_1f1f8-1f1fd.png',
    },
    {
        country: 'Slovakia',
        code: '421',
        iso: 'SK',
        src: '/assets/flags/flag-for-slovakia_1f1f8-1f1f0.png',
    },
    {
        country: 'Slovenia',
        code: '386',
        iso: 'SI',
        src: '/assets/flags/flag-for-slovenia_1f1f8-1f1ee.png',
    },
    {
        country: 'Solomon Islands',
        code: '677',
        iso: 'SB',
        src: '/assets/flags/flag-for-solomon-islands_1f1f8-1f1e7.png',
    },
    {
        country: 'Somalia',
        code: '252',
        iso: 'SO',
        src: '/assets/flags/flag-for-somalia_1f1f8-1f1f4.png',
    },
    {
        country: 'South Africa',
        code: '27',
        iso: 'ZA',
        src: '/assets/flags/flag-for-south-africa_1f1ff-1f1e6.png',
    },
    {
        country: 'South Korea',
        code: '82',
        iso: 'KR',
        src: '/assets/flags/flag-for-south-korea_1f1f0-1f1f7.png',
    },
    {
        country: 'South Sudan',
        code: '211',
        iso: 'SS',
        src: '/assets/flags/flag-for-south-sudan_1f1f8-1f1f8.png',
    },
    {
        country: 'South Georgia And South Sandwich Islands',
        code: '500',
        iso: 'GS',
        src: '/assets/flags/flag-for-south-georgia-south-sandwich-islands_1f1ec-1f1f8.png',
    },
    {
        country: 'Spain',
        code: '34',
        iso: 'ES',
        src: '/assets/flags/flag-for-spain_1f1ea-1f1f8.png',
    },
    {
        country: 'Sri Lanka',
        code: '94',
        iso: 'LK',
        src: '/assets/flags/flag-for-sri-lanka_1f1f1-1f1f0.png',
    },
    {
        country: 'Sudan',
        code: '249',
        iso: 'SD',
        src: '/assets/flags/flag-for-sudan_1f1f8-1f1e9.png',
    },
    {
        country: 'Suriname',
        code: '597',
        iso: 'SR',
        src: '/assets/flags/flag-for-suriname_1f1f8-1f1f7.png',
    },
    {
        country: 'Svalbard and Jan Mayen',
        code: '47',
        iso: 'SJ',
        src: '/assets/flags/flag-for-svalbard-jan-mayen_1f1f8-1f1ef.png',
    },
    {
        country: 'Swaziland',
        code: '268',
        iso: 'SZ',
        src: '/assets/flags/flag-for-swaziland_1f1f8-1f1ff.png',
    },
    {
        country: 'Sweden',
        code: '46',
        iso: 'SE',
        src: '/assets/flags/flag-for-sweden_1f1f8-1f1ea.png',
    },
    {
        country: 'Switzerland',
        code: '41',
        iso: 'CH',
        src: '/assets/flags/flag-for-switzerland_1f1e8-1f1ed.png',
    },
    {
        country: 'Syria',
        code: '963',
        iso: 'SY',
        src: '/assets/flags/flag-for-syria_1f1f8-1f1fe.png',
    },
    {
        country: 'Taiwan',
        code: '886',
        iso: 'TW',
        src: '/assets/flags/flag-for-taiwan_1f1f9-1f1fc.png',
    },
    {
        country: 'Tajikistan',
        code: '992',
        iso: 'TJ',
        src: '/assets/flags/flag-for-tajikistan_1f1f9-1f1ef.png',
    },
    {
        country: 'Tanzania',
        code: '255',
        iso: 'TZ',
        src: '/assets/flags/flag-for-tanzania_1f1f9-1f1ff.png',
    },
    {
        country: 'Thailand',
        code: '66',
        iso: 'TH',
        src: '/assets/flags/flag-for-thailand_1f1f9-1f1ed.png',
    },
    {
        country: 'Togo',
        code: '228',
        iso: 'TG',
        src: '/assets/flags/flag-for-togo_1f1f9-1f1ec.png',
    },
    {
        country: 'Tokelau',
        code: '690',
        iso: 'TK',
        src: '/assets/flags/flag-for-tokelau_1f1f9-1f1f0.png',
    },
    {
        country: 'Tonga',
        code: '676',
        iso: 'TO',
        src: '/assets/flags/flag-for-tonga_1f1f9-1f1f4.png',
    },
    {
        country: 'Trinidad and Tobago',
        code: '1868',
        iso: 'TT',
        src: '/assets/flags/flag-for-trinidad-tobago_1f1f9-1f1f9.png',
    },
    {
        country: 'Tunisia',
        code: '216',
        iso: 'TN',
        src: '/assets/flags/flag-for-tunisia_1f1f9-1f1f3.png',
    },
    {
        country: 'Turkey',
        code: '90',
        iso: 'TR',
        src: '/assets/flags/flag-for-turkey_1f1f9-1f1f7.png',
    },
    {
        country: 'Turkmenistan',
        code: '993',
        iso: 'TM',
        src: '/assets/flags/flag-for-turkmenistan_1f1f9-1f1f2.png',
    },
    {
        country: 'Turks and Caicos Islands',
        code: '1649',
        iso: 'TC',
        src: '/assets/flags/flag-for-turks-caicos-islands_1f1f9-1f1e8.png',
    },
    {
        country: 'Tuvalu',
        code: '688',
        iso: 'TV',
        src: '/assets/flags/flag-for-tuvalu_1f1f9-1f1fb.png',
    },
    {
        country: 'U.S. Virgin Islands',
        code: '1340',
        iso: 'VI',
        src: '/assets/flags/flag-for-us-virgin-islands_1f1fb-1f1ee.png',
    },
    {
        country: 'Uganda',
        code: '256',
        iso: 'UG',
        src: '/assets/flags/flag-for-uganda_1f1fa-1f1ec.png',
    },
    {
        country: 'Ukraine',
        code: '380',
        iso: 'UA',
        src: '/assets/flags/flag-for-ukraine_1f1fa-1f1e6.png',
    },
    {
        country: 'United Arab Emirates',
        code: '971',
        iso: 'AE',
        src: '/assets/flags/flag-for-united-arab-emirates_1f1e6-1f1ea.png',
    },
    {
        country: 'United Kingdom',
        code: '44',
        iso: 'GB',
        src: '/assets/flags/flag-for-united-kingdom_1f1ec-1f1e7.png',
    },
    {
        country: 'United States',
        code: '1',
        iso: 'US',
        src: '/assets/flags/flag-for-united-states_1f1fa-1f1f8.png',
    },
    {
        country: 'Uruguay',
        code: '598',
        iso: 'UY',
        src: '/assets/flags/flag-for-uruguay_1f1fa-1f1fe.png',
    },
    {
        country: 'Uzbekistan',
        code: '998',
        iso: 'UZ',
        src: '/assets/flags/flag-for-uzbekistan_1f1fa-1f1ff.png',
    },
    {
        country: 'Vanuatu',
        code: '678',
        iso: 'VU',
        src: '/assets/flags/flag-for-vanuatu_1f1fb-1f1fa.png',
    },
    {
        country: 'Vatican',
        code: '379',
        iso: 'VA',
        src: '/assets/flags/flag-vatican-city_1f1fb-1f1e6.png',
    },
    {
        country: 'Venezuela',
        code: '58',
        iso: 'VE',
        src: '/assets/flags/flag-for-venezuela_1f1fb-1f1ea.png',
    },
    {
        country: 'Vietnam',
        code: '84',
        iso: 'VN',
        src: '/assets/flags/flag-for-vietnam_1f1fb-1f1f3.png',
    },
    {
        country: 'Wallis and Futuna',
        code: '681',
        iso: 'WF',
        src: '/assets/flags/flag-for-wallis-futuna_1f1fc-1f1eb.png',
    },
    {
        country: 'Western Sahara',
        code: '212',
        iso: 'EH',
        src: '/assets/flags/flag-for-western-sahara_1f1ea-1f1ed.png',
    },
    {
        country: 'West Bank',
        code: '970',
        iso: 'XW',
        src: '/assets/flags/flag-for-palestinian-territories_1f1f5-1f1f8.png',
    },
    {
        country: 'Yemen',
        code: '967',
        iso: 'YE',
        src: '/assets/flags/flag-for-yemen_1f1fe-1f1ea.png',
    },
    {
        country: 'Zambia',
        code: '260',
        iso: 'ZM',
        src: '/assets/flags/flag-for-zambia_1f1ff-1f1f2.png',
    },
    {
        country: 'Zimbabwe',
        code: '263',
        iso: 'ZW',
        src: '/assets/flags/flag-for-zimbabwe_1f1ff-1f1fc.png',
    },
];
