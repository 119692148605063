import { useEffect, useState, useMemo, useCallback } from 'react';
import { Photos } from './PhotoListItemsStyles';
import Photo, { Wrapper } from '../../components/shared/photo/Photo';
import LandscapeImage from '../landscapeImage/LandscapeImage';
import { calcNewIndex } from '../../utils/general';

interface PhotoListProps {
  photos?: any[];
}

const PhotoListItems = ({ photos = [] }: PhotoListProps) => {
  const [index, setIndex] = useState(-1);
  const [currentPhoto, setCurrentPhoto] = useState(photos[index]);

  const onClose = () => {
    setIndex(-1);
  };

  const onPrev = () => {
    setIndex(calcNewIndex(photos.length, index).prev);
  };

  const onNext = () => {
    setIndex(calcNewIndex(photos.length, index).next);
  };

  const onOpen = (clickedIndex: number) => {
    setIndex(clickedIndex);
    console.log('List Item Clicked index:--->', clickedIndex);
  };

  useEffect(() => {
    setCurrentPhoto(photos[index]);
  }, [index]);

  // lazy Load the next and previous images for faster swiping
  useEffect(() => {
    if (index != -1) {
      const siblings = Object.values(calcNewIndex(photos.length, index)).map((idx) => photos[idx]);
      siblings.forEach((photo) => {
        const img = new Image();
        img.src = photo.image.full;
      });
    }
  }, [index]);

  useEffect(() => {
    if (!!currentPhoto) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
      window.addEventListener('scroll', (e) => {
        e.preventDefault();
      });
    } else {
      window.removeEventListener('scroll', (e) => {});
      document.body.style.overflow = 'visible';
      document.body.style.touchAction = 'auto';
    }
    return window.removeEventListener('scroll', (e) => {});
  }, [!!currentPhoto]);

  const memoizedPhotos: any[] = useMemo(
    () =>
      photos.map((photo: any, index: number) => (
        <Wrapper key={photo.id} onClick={() => onOpen(index)}>
          <Photo thumbnail={photo.image.thumbnail} />
        </Wrapper>
      )),
    [photos]
  );

  const divClickedHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const div = event.currentTarget;
    console.log('Element name: ', div, 'Width: ', div.clientWidth, 'Height: ', div.clientHeight);
  };

  return (
    <>
      <Photos>{memoizedPhotos}</Photos>
      {!!currentPhoto && (
        <LandscapeImage
          albumId={currentPhoto.album_id}
          isUnlocked={currentPhoto.is_unlocked}
          imageId={currentPhoto.id}
          image={currentPhoto.image.full}
          key={currentPhoto.id}
          onPrev={onPrev}
          onNext={onNext}
          onClose={onClose}
        />
      )}
    </>
  );
};
export default PhotoListItems;
