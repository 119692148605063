import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../App";
import Button from "../../components/shared/button/Button";
import { Container } from "../../components/shared/container/Container";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import LoaderGif from "../../components/shared/loaderGif/LoaderGif";
import { State } from "../../store";
import { getAlbum, setAlbum, unlockAlbum } from "../../store/actions/user";
import AlbumPhotoList from "./AlbumPhotoList";
import {
  Back,
  BackContainer,
  BackInner,
  ButtonWrapper,
  Data,
  FooterBot,
  AlbumHeader,
  Inner,
  Name,
  Unlock,
  Wrapper,
  WrapperInner,
} from "./AlbumStyles";

const Album = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const albumId = params.id;
  const nav = useNavigate();
  const album = useSelector((state: State) => state.userReducer.album);
  const isPageLoading = useSelector((state: State) => state.userReducer.isLoading);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTop, setLoadingTop] = useState(false);
  
  const timestamp = new Date(album?.date as number);
  const date = {
    month: timestamp.toLocaleString("default", { month: "short" }),
    day: timestamp.getDate(),
    year: timestamp.getFullYear(),
  };

  const user = useSelector((state: State) => state.userReducer.user);
  const tempSelfie = useSelector(
    (state: State) => state.userReducer.tempSelfie
  );
  useEffect(() => {
    dispatch(getAlbum(albumId as string));
  }, []);

  const onBackClick = () => {
    dispatch(setAlbum(null));
    nav("/dashboard");
  };

  const onUnlockClick = async (top: boolean) => {
    top ? setLoadingTop(true) : setLoading(true);

    await dispatch(unlockAlbum(albumId as string));
  };

  if (isPageLoading) {
    return (<LoaderGif isLoading />)
  }

  return (   
    <Wrapper>
      {album?.photos ? (
        <div>
          <WrapperInner photoCount={album?.photos?.length ? album.photos.length : 0}>
            <Header
              imageSrc={
                user?.selfie?.photo_url
                  ? user.selfie.photo_url
                  : tempSelfie
                  ? tempSelfie
                  : "/assets/images/avatar-icon.png"
              }
            /> 
            <BackContainer>
              <BackInner onClick={onBackClick}>
                <Back
                  src="/assets/icons/arrow-back.svg"
                  alt=""
                />
                <span>Home</span>
              </BackInner>
            </BackContainer>
            <AlbumHeader>
              <Inner>
                <Data>
                  <Name>{album.name}</Name>
                  <FooterBot>
                    {`${album.location} • ${date.month} ${date.day}, ${date.year}`}
                  </FooterBot>
                  {album.is_unlocked ? null : (
                    <Unlock onClick={() => onUnlockClick(true)}>
                      Unlock your photos
                    </Unlock>
                  )}
                </Data>
                <LoaderGif isLoading={isLoadingTop} />
              </Inner>
            </AlbumHeader>
            <AlbumPhotoList album={album} />
            {album.is_unlocked ? null : (
              <Container>
                <ButtonWrapper>
                  <Button
                    isLoading={isLoading}
                    onClick={() => onUnlockClick(false)}
                  >
                    Unlock your photos
                  </Button>
                </ButtonWrapper>
              </Container>
            )}
          </WrapperInner>
          <Footer />
        </div>
      ) : null}
    </Wrapper>
  );
};

export default Album;
