import SanityLink from './SanityLink';
import SanitySvg from './SanitySvg';

const SanityLogoLink = ({ link }: { link: any }) => {
  return (
    <SanityLink {...link}>
      <SanitySvg image={link.logo} />
    </SanityLink>
  );
};

export default SanityLogoLink;
