import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../App";
import Header from "../../components/shared/header/Header";
import { State } from "../../store";
import { getSelfie } from "../../store/actions/user";
import { Heading, Inner, Wrapper } from "./PrivacyPolicyStyles";

const PrivacyPolicy = () => {
  const selfie = useSelector((state: State) => state.userReducer.selfie);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!selfie) {
      dispatch(getSelfie());
    }
  });
useEffect(() => {
  window.scrollTo(0, 0);
});
  return (
    <Wrapper>
      <Header imageSrc={selfie} />

      <Inner>
        <Heading>Privacy Policy</Heading>
        <p className="photodrop-futura">
          Your privacy is very important to us. Accordingly, we have developed
          this Privacy Policy in order for you to understand how we collect,
          use, and disclose information that we receive through our Service. The
          FOM Online, Inc. (“PhotoDrop,” “us,” “we,” or “our”) website available
          at <span style={{ fontWeight: "700" }}>photodrop.me</span> and the
          PhotoDrop photo finding service (together, the “Service”) are owned
          and operated by PhotoDrop. This Privacy Policy does not apply to any
          third party websites, services or applications, even if they are
          accessible through our Service. Also, please note that, unless we
          define a term in this Privacy Policy, all capitalized terms used in
          this Privacy Policy have the same meanings as in our Terms of Service.
          So, please make sure that you have read and understand our Terms of
          Service.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>User Consent</span>
        </div>

        <p className="photodrop-futura">
          By accessing or otherwise using the Service, you agree to the terms
          and conditions of this Privacy Policy and the associated Terms of
          Service (set forth on PhotoDrop) you expressly consent to the
          processing of your Personal Information (as defined below) and
          Anonymous Information (as defined below) according to this Privacy
          Policy.
        </p>

        <p className="photodrop-futura">
          Your Personal Information may be processed by us in the country where
          it was collected and transferred to, and maintained on, computers
          located outside of your state, province, country (including the United
          States), or other governmental jurisdiction where privacy laws
          regarding processing of Personal Information may be less stringent
          than the laws in your country. If you’re located outside the United
          States and choose to provide your Personal Information to us, we may
          transfer your Personal Information to the United States and process it
          there.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Regarding Children</span>
        </div>

        <p className="photodrop-futura">
          Children under the age of 13 are not permitted to use the Service and
          we do not intentionally collect or maintain Personal Information from
          those who are under 13 years old. Protecting the privacy of children
          is very important to us. Thus, if we obtain actual knowledge that a
          user is under 13, we will take steps to remove that user’s Personal
          Information from our databases. We recommend that children between the
          ages of 13 and 18 obtain their parent’s permission before submitting
          information over the internet. By using the Service, you are
          representing that you are at least 18 years old, or that you are at
          least 13 years old and have your parents’ permission to use the
          Service.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Collection and Use of Information
          </span>
        </div>

        <p className="photodrop-futura">
          Our Service is designed to help you more easily identify photos of
          yourself, to share those photos with other users, and to connect and
          share photos with friends and other users. You are in control of what
          information you share with the Service and nothing you provide is
          automatically shared with others unless you tell PhotoDrop via your
          settings to send photos to other users without your review. We provide
          you with settings to allow you to control your level of approval for
          your photos, and if you no longer want to be identified in a certain
          photo you can simply untag it.
        </p>

        <p className="photodrop-futura">
          Our primary goals in collecting information are to provide and improve
          our Service, to administer your use of the Service (including your
          Account, if you are an Account holder), and to enable you to enjoy and
          easily navigate our Service.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Personal Information</span>
        </div>

        <p className="photodrop-futura">
          In general, we collect information that can be used to identify you,
          such as your name, email address, and phone number (“Personal
          Information”) that you submit to us voluntarily through the Service,
          including Personal information that you submit in the process of
          creating or editing your Account and user profile on the Service. For
          example, our registration and login process requires you to provide us
          with your name, mobile phone number, valid email address and password
          of your choice.
        </p>

        <p className="photodrop-futura">
          When you personalize your profile and use the features of the Service,
          we will collect any Personal Information you voluntarily provide, and
          we may also request optional non-personal information to support your
          use of the Service, such as your year of birth, gender and other
          demographic information. We refer to such information as “Anonymous
          Information” because it cannot be used by itself to identify you and
          therefore is not Personal Information. We collect information in the
          form of the User Content that you submit during your use of the
          Service, such as photos, comments, ratings and other information you
          choose to submit.
        </p>

        <p className="photodrop-futura">
          We also collect the information you provide when you use the Service.
          This can include information in or about the User Content you provide,
          such as the location of a photo or the date a file was created. If you
          connect to us from a social network, we also will be able to access
          and collect Personal Information about you and your friends that your
          privacy settings on the social network permit us to access. For
          instance, we may collect your user ID or profile information that you
          have permitted to be displayed through the Service in order to display
          you as a friend or in association with your profile and collections.
        </p>

        <p className="photodrop-futura">
          When you order our for fee products or services, you will need to
          submit Personal Information such as your credit card number, postal
          address, or other payment information so that our service providers
          can process your payment for those products and services. If you
          choose to sign up to receive information about products or services
          that may be of interest to you, we will collect your email address and
          all related information. Additionally, we collect any information that
          you voluntarily enter, including Personal Information, into any
          postings, comments, or forums within the PhotoDrop community.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Use of Facial Recognition Data
          </span>
        </div>

        <p className="photodrop-futura">
          We do not sell, trade, or rent any Personal Information derived from
          our use of facial recognition technology. As detailed below, we use a
          variety of reasonable, industry-standard security technologies and
          administrative, physical, and electronic procedures to help protect
          your Personal Information, including information derived from our use
          of facial recognition technology, from unauthorized access, use, or
          disclosure. We may provide secure access to this information with
          third-party service providers who work on our behalf to administer and
          provide certain components or features of the Service.
        </p>

        <p className="photodrop-futura">
          We will retain and store Personal Information derived from the facial
          recognition technology for as long as you have an active Account on
          the Service. Personal Information derived from facial recognition
          technology will be deleted or destroyed through a secure manner. If
          you are a parent or guardian and believe that we might have
          inadvertently collected any personal information from your child under
          the age of 13 through the Service, please contact us at
          hello@photodrop.me to request that information be deleted or
          destroyed.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Device Information</span>
        </div>

        <p className="photodrop-futura">
          We collect information from and about the computers, phones, or other
          devices where you install or access the Service, depending on the
          permissions you have granted. We may associate the information we
          collect from your different devices to provide a better or more
          consistent experience. Information we may collect includes device
          identifiers, user settings, and the operating system of your device,
          as well as information about your use of our Service.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Location Information</span>
        </div>

        <p className="photodrop-futura">
          When you use our App, we may collect and store information about your
          location by converting your IP address into a rough geo-location or by
          accessing your mobile device’s GPS coordinates or coarse location if
          you enable location services on your device. We may use location
          information to improve and personalize our Service for you, such as to
          identify photos of you near a particular location. If you do not want
          us to collect location information, you may disable that feature on
          your mobile device. However, if you disable the location services
          feature on your mobile device after using the App, we cannot remove
          any historic location data unless you delete your Account.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Personal Information from Other Sources
          </span>
        </div>

        <p className="photodrop-futura">
          We may receive Personal Information about you from other sources with
          which you have registered, companies with whom we have partnered with
          or other third parties. We may associate this information with the
          other Personal Information we have collected about you.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>E-mail and Email Addresses</span>
        </div>

        <p className="photodrop-futura">
          If you send an e-mail to us, or fill out our “Feedback” form through
          the Service, we will collect your e-mail address and the full content
          of your e-mail, including attached files, and other information you
          provide. We may use and display your full name and email address when
          you send an email notification to a friend through the Service or the
          social network from which you have connected to the Service (such as
          in an invitation, or when sharing your content). Additionally, we use
          your email address to contact you on behalf of your friends (such as
          when someone sends you a personal message) or notifications from a
          social network or other website with whom you have registered to
          receive such notifications. We may use this e-mail address to contact
          you, for things such as notifications of limited edition shop sales
          and other related information. You may indicate your preference to
          stop receiving further promotional communications as further detailed
          below.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Information Collected Automatically
          </span>
        </div>

        <p className="photodrop-futura">
          As you use the Service, certain information about how a person uses
          our Service may also be passively collected and stored on our or our
          service providers’ server logs, including your Internet protocol
          address, browser type, and operating system. We also use navigational
          data like Uniform Resource Locators (URL) to gather information
          regarding the date and time of your visit and the solutions and
          information for which you searched and viewed, or on which of the
          advertisements displayed on the Service you clicked. This type of
          information is collected to administer the Service, to make the
          Service more useful to you, to tailor your experience with the Service
          to meet your special interests and needs, and to analyze (and have
          third parties analyze) the information to improve, customize and
          enhance our Service.
        </p>

        <p className="photodrop-futura">
          An “Internet protocol address” or “IP Address” is a number that is
          automatically assigned to your computer when you use the Internet. In
          some cases your IP Address stays the same from browser session to
          browser session; but if you use a consumer internet access provider,
          your IP Address probably varies from session to session. For example,
          we, or our service providers, may track your IP Address when you
          access the Service to assist with ad targeting.
        </p>

        <p className="photodrop-futura">
          “Cookies” are small pieces of information that a website sends to your
          computer’s hard drive while you are viewing a website. We may use both
          session Cookies (which expire once you close your web browser) and
          persistent Cookies (which stay on your computer until you delete them)
          to tell us how and when you interact with our Services, to monitor
          aggregate usage and web traffic routing on our Service, to improve our
          Service, and to provide you with a more personal and interactive
          experience with the Service. Persistent Cookies can be removed by
          following your Internet browser help file directions. In order to use
          our Service, your web browser must accept Cookies. If you choose to
          disable Cookies, some aspects or features of the Service may not work
          properly, and you will not be able to receive our Service.
        </p>

        <p className="photodrop-futura">
          We may also enable advertisers and ad servers to promote third-party
          products and/or services by placing advertisements on the Service.
          These advertisers and ad servers may use Cookies and/or “Web Beacons”
          (which are usually small, transparent graphic images). Web Beacons are
          used in order to deliver or communicate Cookies, to track and measure
          the performance of our Service, to monitor how many visitors view our
          Service, and to monitor information related to the effectiveness of
          served advertisements. Clicking on such advertisements will direct you
          to the website of a third-party and the advertiser. This Privacy
          Policy covers only our use of Cookies and Web Beacons and does not
          cover the use of Cookies, Web Beacons, and other privacy practices of
          any advertisers or ad servers.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Use and Disclosure of Information
          </span>
        </div>

        <p className="photodrop-futura">
          Except as otherwise stated in this Privacy Policy, we do not generally
          sell, trade, rent, or share your Personal Information with third
          parties, unless you ask or authorize us to do so.
        </p>

        <p className="photodrop-futura">
          In general, Personal Information you submit to us is used by us to
          provide you access to the Service, to improve the Service, to better
          tailor the features, performance, and support of the Service and to
          offer you additional information, opportunities, promotions and
          functionality from us, our partners or our advertisers at your
          request. Additionally, we do share your content preferences and other
          information with the social network from which you have connected to
          the Service, along with those companies and persons you have asked us
          to share your information with.
        </p>

        <p className="photodrop-futura">
          We may provide your Personal Information to third-party service
          providers who work on behalf of or with us to administer and provide
          some of the services and features of the Service and to help us
          communicate with you. Examples of such services include sending email,
          analyzing data, providing marketing assistance, processing payments
          (including credit card payments), quality control (such as manually or
          mechanically comparing reference images with photo matches to
          determine the accuracy of matches), creating biometric identifiers and
          providing customer service. We require our third-party service
          providers to promise not to use such information except as necessary
          to provide the relevant services to us and not to disclose or use your
          Personal Information for any other purpose. We may also share
          aggregated information and Anonymous Information with third parties
          for research and analysis, demographic profiling and other similar
          purposes.
        </p>

        <p className="photodrop-futura">
          We may share some or all of your Personal Information with our
          third-party partners, those with whom we have a co-branding or
          promotional relationship, or other third-parties about whom you are
          otherwise notified and do not request to opt out of such sharing. This
          Privacy Policy does not cover the use of your Person Information by
          such third-parties. We do not maintain responsibility for the manner
          in which third parties, including, without limitation, social
          networks, other partners and advertisers, use or further disclose
          Personal Information collected from you in accordance with this
          Privacy Policy, after we have disclosed such information to those
          third parties. If you do not want us to use or disclose Personal
          Information collected about you in the manners identified in this
          Privacy Policy, you may not use the Service.
        </p>

        <p className="photodrop-futura">
          Although we currently do not have a parent company, any subsidiaries,
          joint ventures, or other companies under a common control, we may in
          the future, and we may share some or all of your Personal Information
          with these companies, in which case we will require them to honor this
          Privacy Policy.
        </p>

        <p className="photodrop-futura">
          Information that we collect from our users, including Personal
          Information, is considered a business asset. In the event we go
          through a business transition such as a merger, acquisition by another
          company, or sale of all or a portion of our assets, your Personal
          Information may be among the assets transferred. You acknowledge that
          such transfers may occur and are permitted by this Privacy Policy.
        </p>

        <p className="photodrop-futura">
          We cooperate with government and law enforcement officials or private
          parties to enforce and comply with the law. We may disclose your
          Personal Information to government or law enforcement officials or
          private parties if we believe in good faith that such disclosure is
          necessary or appropriate to (a) comply with relevant laws and to stop
          any activity that we consider illegal, unethical, or legally
          actionable activity; (b) to respond to claims, subpoenas or warrants
          served on us; or (c) to protect and defend our rights, property, and
          safety and the rights, property, and safety or you or third parties.
          You hereby consent to us sharing your Personal Information under the
          circumstances described herein.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            The Ability of others to View your Information
          </span>
        </div>

        <p className="photodrop-futura">
          Helping you to protect your information is a vital part of our
          mission. It is up to you to make sure you are comfortable with the
          information you choose to provide us and the information you choose to
          publish. You understand that when you use the Service, certain
          information you post or provide through the Service, such as your
          name, profile, comments, posts and ratings, may be shared with other
          users and posted on publicly available portions of the Service,
          including without limitation, chat rooms, bulletin and message boards,
          along with other public forums. Please keep in mind that if you choose
          to disclose Personal Information when posting comments or other
          information or content through the Service, this information may
          become publicly available and may be collected and used by others,
          including people outside the PhotoDrop community. We will not have any
          obligations with respect to any information that you post to parts of
          the Service available to others, and recommend that you use caution
          when giving out Personal Information to others in public forums online
          or otherwise. We also share the information you publish with other
          third parties as set forth in this Privacy Policy.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Responding to Do Not Track Signals
          </span>
        </div>

        <p className="photodrop-futura">
          Our website does not have the capability to respond to “Do Not Track”
          signals received from various web browsers.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Third Party Sites and Advertising
          </span>
        </div>

        <p className="photodrop-futura">
          The Service may contain links to other websites. Please be aware that
          we are not responsible for the privacy practices or the content of
          such other websites. Any information that you provide on or to a third
          party website or that is collected by a third party service is
          provided directly to the owner or operator of the third party website
          or service and is subject to the owner’s or operator’s privacy policy.
          We encourage our users to read the privacy statements of each and
          every website they visit and service they use. This Privacy Policy
          applies solely to information collected by us through the Service and
          does not apply to these third-party websites. The ability to access
          information of third-parties from the Service, or links to other
          websites or locations, is for your convenience and does not signify
          our endorsement of such third-parties, their products, their services,
          other websites, locations or their content.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>
            Your Choices Regarding Your Personal Information
          </span>
        </div>

        <p className="photodrop-futura">
          We offer you choices regarding the collection, use, and sharing of
          your Personal Information. Please note that if you decide not to
          provide us with the Personal Information that we request, you may not
          be able to access all of the features of the Service.
        </p>

        <p className="photodrop-futura">
          We may periodically send you free newsletters and e-mails that
          directly promote our Service. When you receive promotional
          communications from us, you may indicate a preference to stop
          receiving further promotional communications from us and you will have
          the opportunity to “opt-out” by following the unsubscribe instructions
          provided in the promotional e-mail you receive or by contacting us
          directly (please see contact information below).
        </p>

        <p className="photodrop-futura">
          Despite your indicated email preferences, we may send you
          administrative emails regarding the Service, including, for example,
          administrative confirmations, notices of updates to our Privacy Policy
          or Terms of Service, and information about billing if we choose to
          provide such notices to you in this manner. You will not be able to
          opt out of those communications.
        </p>

        <p className="photodrop-futura">
          You may change any of your profile information, including any Personal
          Information associated with your Account, by editing it in the profile
          settings page. You may request deletion of your Personal Information
          and Account by contacting us at hello@photodrop.me, but please note
          that we may be required (by law or otherwise) to keep this information
          and not delete it (or to keep this information for a certain time, in
          which case we will comply with your deletion request, only after we
          have fulfilled such requirements). If you request deletion of your
          Account, we will delete photos you added to the Service, as well as
          information derived from facial recognition technology from the
          reference image that you added. When we delete Personal Information,
          it will be deleted from the active database.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Security</span>
        </div>

        <p className="photodrop-futura">
          We are committed to protecting the security of your Personal
          Information. We use a variety of reasonable, industry-standard
          security technologies and administrative, physical, and electronic
          procedures to help protect your Personal Information from unauthorized
          access, use, or disclosure. When you enter sensitive information on
          our forms, we encrypt this data using SSL or other technologies. Even
          though we have taken significant steps to protect your Personal
          Information, no company, including us, can fully eliminate security
          risks associated with Personal Information. Accordingly, we cannot
          guarantee the absolute security of any information.
        </p>

        <div className="photodrop-futura">
          <span style={{ fontWeight: "700" }}>Contact and Revisions</span>
        </div>

        <p className="photodrop-futura">
          Any information that is collected via our Service is covered by the
          Privacy Policy in effect at the time such information is collected.
          This Privacy Policy is subject to occasional revision at our
          discretion, and if we make any substantial changes in the way we
          collect, use, or disclose your Personal Information, we will post an
          alert on this page and we’ll update the “Last Updated Date” above to
          indicate when those changes will become effective. If you object to
          any such changes, you must cease using the Service. Continued use of
          the Service following notice of any such changes shall indicate your
          acknowledgement of such changes and agreement to be bound by the terms
          and conditions of such changes.
        </p>

        <p className="photodrop-futura">
          If you have any questions about these Terms or the Service or Privacy
          Policy, please contact PhotoDrop at{" "}
          <a href="mailto:hello@photodrop.me" style={{ color: "#3300CC" }}>
            hello@photodrop.me
          </a>
          .
        </p>
      </Inner>
    </Wrapper>
  );
};

export default PrivacyPolicy;
