import { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface Props {
  thumbnail?: string;
}

const Photo = ({ thumbnail }: Props) => {
  return <Img src={thumbnail} loading="lazy" />;
};
export const Img = styled.img`
  height: 33.33vw;
  object-fit: cover;
  width: 100%;
  flex: 0 1 33.33333%;
  cursor: pointer;
  background: #d3d3d3;
  @media (min-width: 1440px) {
    height: 400px;
  }
`;
export const Wrapper = styled.div`
  height: 33.33vw;
  object-fit: cover;
  max-width: calc(33.33% - 1px);
  flex: 0 1 33.33333%;
  cursor: pointer;
  background: #d3d3d3;
  width: 100%;
  @media (min-width: 1440px) {
    height: 400px;
  }
`;
export default Photo;
