import client from '../../../utils/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import SVG from 'react-inlinesvg';

const SanitySvg = ({ image }: { image: any }) => {
  const urlBuilder = imageUrlBuilder(client);
  return <SVG src={urlBuilder.image(image).url()} />;
};

export default SanitySvg;
