import { PortableText } from '@portabletext/react';

type PropsType = {
  blocks?: Array<any>;
};
const PortableContent = ({ blocks }: PropsType) => {
  if (!blocks) return null;
  return <PortableText value={blocks} />;
};

export default PortableContent;
