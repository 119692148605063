import { Link } from 'react-router-dom';

type PropsType = {
  link?: any;
  title?: any;
  linkTarget?: boolean;
  route?: any;
  children: JSX.Element;
};

const SanityLink = ({
  link,
  route,
  title,
  linkTarget,
  children,
}: PropsType) => {
  if (route) {
    return (
      <Link to={route.slug.current} replace>
        {title}
      </Link>
    );
  }
  if (link) {
    return (
      <a
        href={link}
        target={linkTarget === true ? '_blank' : '_self'}
        rel="noreferrer"
        title={title}
      >
        {children}
      </a>
    );
  }
  return children;
};

export default SanityLink;
