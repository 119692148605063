import sanityClient from '@sanity/client';

const client = sanityClient({
  projectId: '85bbauyd',
  dataset: 'production',
  useCdn:
    typeof document !== 'undefined' && process.env.NODE_ENV === 'production',
  apiVersion: '2022-03-13',
  token: process.env.SANITY_TOKEN,
  ignoreBrowserTokenWarning: true,
});

export default client;
