import styled from "styled-components";

export const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  flex: 1 1 30%;
  width: calc(100% + 1px);
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  gap: 1px;
  @media (min-width: 1440px) {
    max-width: 1200px;
    margin: 0 auto 0;
  }
`;
