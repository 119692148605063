import styled from 'styled-components';

export const BackContainer = styled.div`
  padding: 15px 15px 38px;
`;
export const BackInner = styled.div`
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  cursor: pointer;
  width: fit-content;
`;
export const AlbumHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px 18px;
  position: relative;
  @media (min-width: 1440px) {
    min-height: 61px;
  }
`;
export const Wrapper = styled.div`
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;
export const WrapperInner = styled.div<{
  photoCount: number;
  }>`
  margin: 0 0 ${(props) => (props.photoCount <= 6 ? '33vh' : '0')};
`;
export const Data = styled.div`
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 38px;
  flex-direction: column;
  row-gap: 5px;
  @media (min-width: 1440px) {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    flex-direction: row;
    row-gap: 0;
  }
`;
export const Back = styled.img`
  width: 8px;
  height: 16px;
  cursor: pointer;
  @media (min-width: 1440px) {
    width: 10px;
    height: 20px;
    position: absolute;
    left: 40px;
  }
`;

export const Name = styled.div`
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  //line-height: 22px;
  color: #262626;
  @media (min-width: 1440px) {
    height: unset;
    font-size: 22px;
    line-height: 26px;
  }
`;
export const FooterBot = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #262626;
  @media (min-width: 1440px) {
    height: unset;
    font-size: 16px;
    line-height: 21px;
  }
`;
export const Inner = styled.div`
  height: 100%;
  @media (min-width: 1440px) {
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
  }
`;

export const Unlock = styled.div`
  display: none;
  cursor: pointer;
  margin-left: auto;
  @media (min-width: 1440px) {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #3300cc;
    display: block;
  }
`;

export const Count = styled.span`
  color: #3300cc;
  margin: 0 0 0 5px;
`;

export const ButtonWrapper = styled.div`
  margin: 40px auto 40px;
  @media (min-width: 1440px) {
    width: 100%;
    max-width: 300px;
    margin: 100px auto 100px;
  }
`;
