import styled from 'styled-components';
import navigation from '../../../navigation-settings.json';
import PortableContent from './PortableContent';
import SanityLink from './SanityLink';
import SanityLogoLink from './SanityLogoLink';

const Footer = () => {
  return (
    <div>
      <FooterWrapper background={navigation.footerBackground}>
        <InnerWrapper>
          <div>
            {navigation.navLinks && (
              <nav>
                <NavList>
                  {navigation.navLinks.map((link) => (
                    <NavListItem key={link._key}>
                      <SanityLink {...link}>
                        <span>{link.title}</span>
                      </SanityLink>
                    </NavListItem>
                  ))}
                </NavList>
              </nav>
            )}
          </div>
          <NavBlock>
            <Authors>
              <PortableContent blocks={navigation.footerHeading} />
            </Authors>
            {navigation.footerLogoLink && (
              <>
                {navigation.footerLogoLink.map((link) => (
                  <SanityLogoLink link={link} key={link._key} />
                ))}
              </>
            )}
          </NavBlock>
          {navigation.climateLogoLink && (
            <NavBlock>
              {navigation.climateLogoLink.map((link) => (
                <SanityLogoLink link={link} key={link._key} />
              ))}
            </NavBlock>
          )}
          <NavBlock>
            &copy; {new Date().getFullYear()} {navigation.footerCopyRight}
          </NavBlock>
        </InnerWrapper>
      </FooterWrapper>
    </div>
  );
};

const InnerWrapper = styled.div`
  max-width: 420px;
  text-align: center;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 900px) {
    text-align: left;
    display: flex;
    max-width: 790px;
    justify-content: space-between;
  }
`;

const NavList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const NavListItem = styled.li`
  margin-bottom: 15px;

  a {
    text-decoration: none;
    cursor: pointer;
    color: #fff;

    :hover {
      font-weight: 500;
      transition: all 250ms ease-in-out;
    }
  }
`;

const Authors = styled.div`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: var(--color-white);
  margin-bottom: 9px;
`;

const NavBlock = styled.div`
  a {
    svg {
      width: 120px;
      height: auto;
    }
  }

  margin-top: 25px;
  justify-content: center;
  @media screen and (min-width: 889px) {
    margin-right: 60px;
    margin-top: 0;
  }
`;

export const FooterWrapper = styled.div<{ background?: string }>`
  background: ${(props) => props.background || '#262626'};
  width: 100%;
  color: #ffffff;
  margin-top: 110px;
  padding: 60px 15px 134px;
  @media (min-width: 1440px) {
    padding: 60px 0 40px;
  }
`;

export default Footer;
